<template>
  <v-container>
    <v-dialog
      v-model="cropperDialog"
      width="900"
      @click:outside="_closeCropper"
    >
      <v-card>
        <v-card-text :class="$vuetify.breakpoint.smAndUp ? '' : 'px-2'">
          <v-container>
            <v-row>
              <v-col cols="12" class="mt-3 d-flex justify-center">
                <v-img
                  class=""
                  src="@/assets/images/weChamber/logo-WC-main-nav-drawer.svg"
                  max-width="190"
                  height="auto"
                  contain
                ></v-img>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <p class="headline font-weight-bold text-center mb-0">
                  <slot name="dialogTitle">Banner de membresía</slot>
                </p>
              </v-col>
              <v-col cols="12" class="subtitle-1 text-justify py-0">
                <p>
                  <slot name="dialogSubtitle"></slot>
                </p>
              </v-col>
              <v-row>
                <v-col cols="12" class="mt-3">
                  <v-switch
                    v-model="showMultipleImages"
                    label="Activar carrusel de imágenes"
                    color="primary"
                    @change="handleSwitchChange"
                  ></v-switch>
                </v-col>
              </v-row>

              <!-- Sección para mostrar imágenes pre-cargadas -->

              <v-col cols="12" v-if="image.src == null">
                <v-card
                  elevation="0"
                  color="#f1f0ef"
                  class="pa-6 d-flex justify-center rounded-lg"
                  @click="$refs.fileCropper.click()"
                >
                  {{
                    showCarouselSwitch
                      ? 'Click para cargar imagen 1'
                      : 'Click para cargar imagen'
                  }}
                </v-card>
                <input
                  type="file"
                  @change="uploadImage($event, loadImage)"
                  style="display: none"
                  :accept="['.jpg', '.jpeg', '.png']"
                  ref="fileCropper"
                />
              </v-col>
              <v-col cols="12" v-else>
                <cropper
                  ref="cropper"
                  class="cropper"
                  :src="image.src"
                  :max-height="flyer ? flyerLimitations.maxHeight : bannerLimitations.maxHeight"
                  :max-width="flyer ? flyerLimitations.maxWidth : bannerLimitations.maxWidth"
                  :min-height="flyer ? flyerLimitations.minHeight : bannerLimitations.minHeight"
                  :min-width="flyer ? flyerLimitations.minWidth : bannerLimitations.minWidth"
                  :image-restriction="'fit-area'"
                  :size-restrictions-algorithm="percentsRestriction"
                />
                <v-img v-if="showImage1" :src="flyersArray[0]"></v-img>
              </v-col>
            </v-row>
            <v-col cols="12"> </v-col>

            <!-- Cuadros para cargar imágenes adicionales cuando showMultipleImages está activado -->
            <v-col cols="12" v-if="showMultipleImages && image2.src == null">
              <v-card
                elevation="0"
                color="#f1f0ef"
                class="pa-6 d-flex justify-center rounded-lg"
                @click="$refs.fileCropper2.click()"
              >
                Click para cargar imagen 2
              </v-card>
              <input
                type="file"
                @change="uploadImage2($event, loadImage2)"
                style="display: none"
                :accept="['.jpg', '.jpeg', '.png']"
                ref="fileCropper2"
              />
            </v-col>

            <cropper
              v-if="showMultipleImages && image2.src"
              ref="cropper2"
              class="cropper"
              :src="image2.src"
              :max-height="flyer ? flyerLimitations.maxHeight : bannerLimitations.maxHeight"
              :max-width="flyer ? flyerLimitations.maxWidth : bannerLimitations.maxWidth"
              :min-height="flyer ? flyerLimitations.minHeight : bannerLimitations.minHeight"
              :min-width="flyer ? flyerLimitations.minWidth : bannerLimitations.minWidth"
              :image-restriction="'fit-area'"
              :size-restrictions-algorithm="percentsRestriction"
            />

            <v-img
              v-if="showMultipleImages && showImage2"
              :src="flyersArray[1]"
            ></v-img>

            <!-- Cuadro para cargar y recortar imagen 3 -->
            <v-col cols="12" v-if="showMultipleImages && image3.src == null">
              <v-card
                elevation="0"
                color="#f1f0ef"
                class="pa-6 d-flex justify-center rounded-lg"
                @click="$refs.fileCropper3.click()"
              >
                Click para cargar imagen 3
              </v-card>
              <input
                type="file"
                @change="uploadImage3($event, loadImage3)"
                style="display: none"
                :accept="['.jpg', '.jpeg', '.png']"
                ref="fileCropper3"
              />
            </v-col>

            <cropper
              v-if="showMultipleImages && image3.src"
              ref="cropper3"
              class="cropper"
              :src="image3.src"
              :max-height="flyer ? flyerLimitations.maxHeight : bannerLimitations.maxHeight"
              :max-width="flyer ? flyerLimitations.maxWidth : bannerLimitations.maxWidth"
              :min-height="flyer ? flyerLimitations.minHeight : bannerLimitations.minHeight"
              :min-width="flyer ? flyerLimitations.minWidth : bannerLimitations.minWidth"
              :image-restriction="'fit-area'"
              :size-restrictions-algorithm="percentsRestriction"
            />

            <v-img
              v-if="showMultipleImages && flyersArray.length > 0 && showImage3"
              :src="flyersArray[2]"
            ></v-img>

            <v-row
              class="d-flex pb-2 mt-5"
              :class="
                $vuetify.breakpoint.smAndUp ? 'justify-end' : 'justify-center'
              "
            >
              <v-btn
                v-if="showChangeImagesButton"
                @click="changeImages"
                color="primary"
                rounded
              >
                Modificar imágenes
              </v-btn>
              <v-btn
                class="mx-2 white--text"
                color="red"
                @click="deleteImage1"
                rounded
                v-if="image.src != null && showChangeImagesButton == false"
              >
                Eliminar imagen
              </v-btn>
              <v-btn
                v-if="
                  showMultipleImages &&
                  image2.src &&
                  showChangeImagesButton == false
                "
                @click="deleteImage2"
                color="red"
                class="white--text mx-2"
                rounded
              >
                Eliminar imagen 2
              </v-btn>
              <v-btn
                @click="deleteImage3"
                color="red"
                class="white--text mx-2"
                rounded
                v-if="
                  showMultipleImages &&
                  image3.src &&
                  showChangeImagesButton == false
                "
              >
                Eliminar imagen 3
              </v-btn>
              <v-btn
                class="mx-2"
                color="grey lighten-2"
                rounded
                :disabled="disableComponent"
                @click="_closeCropper(), (image.src = null)"
                elevation="0"
              >
                <span class="font-weight-bold black--text">Cerrar</span>
              </v-btn>
              <v-btn
                class="mx-2"
                color="accent"
                rounded
                elevation="0"
                @click="cropImage"
                :disabled="disableComponent || image.src == null"
              >
                <span class="font-weight-bold black--text">Guardar</span>
              </v-btn>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import { mapState, mapMutations } from 'vuex';

export default {
  props: {
    flyers: {
      type: String,
      default: '',
    },
    showCarouselSwitch: {
      type: Boolean,
      default: false,
    },
    cropperDialog: {
      type: Boolean,
      default: false,
    },
    disableComponent: {
      type: Boolean,
      default: false,
    },
    flyer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showChangeImagesButton: false, // Agregar esta variable

      superArreglo: [],
      showImage1: false, // Para rastrear la visibilidad de la imagen 1
      showImage2: false, // Para rastrear la visibilidad de la imagen 2
      showImage3: false,
      banderaImage: false,
      bannerError: "Las dimensiones del banner no pueden ser mayores o menores a 1500x500 pixeles",
      flyerError: "El ancho del flyer debe ser de 800 pixeles",
      image: {
        src: null,
        type: 'image/jpg',
      },
      image2: {
        src: null,
        type: 'image/jpg',
      },
      image3: {
        src: null,
        type: 'image/jpg',
      },
      bannerLimitations: {
        minWidth: 1600,
        maxWidth: 1600,
        minHeight: 500,
        maxHeight: 500,
      },
      flyerLimitations: {
        maxWidth: 1000,
        minWidth: 800,
        minHeight: 300
      },
      showMultipleImages: this.hasThreeLinks,
    };
  },
  watch: {
    hasThreeLinks(newValue) {
      if (newValue) {
        this.image.src = '../../assets/images/dashboard/flyerPlaceholder.png';
        this.image2.src = '../../assets/images/dashboard/flyerPlaceholder.png';
        this.image3.src = '../../assets/images/dashboard/flyerPlaceholder.png';
      } else {
        this.image.src = null;
        this.image2.src = null;
        this.image3.src = null;
      }
    },
    isFlyerWithThreeLinks(newValue) {
      this.showMultipleImages = newValue;
    },
    eventSelected: {
      handler() {
        this.showMultipleImages = this.hasThreeLinks;
      },
      deep: true,
    },
  },

  mounted() {
    this.superArreglo = this.flyers.split(',');

    // Verificar si la imagen 1 está precargada
    if (this.superArreglo[0] !== null) {
      this.image.src = this.superArreglo[0]; // Establecer la fuente de la imagen 1 directamente
      setTimeout(() => {
        this.showImage1 = true; // Mostrar la imagen 1 después de un breve retraso
      }, 10); // Puedes ajustar el valor del retraso según sea necesario
    } else {
      // Si la imagen 1 no está precargada, cargar la imagen de marcador de posición
      this.image.src = '../../assets/images/dashboard/flyerPlaceholder.png';
    }

    if (this.superArreglo[1] !== null) {
      this.showImage2 = true;
    }
    if (this.superArreglo[2] !== null) {
      this.showImage3 = true;
      this.showChangeImagesButton = true;
    }

    // Configurar showChangeImagesButton cuando las 3 imágenes están precargadas
    if (this.showImage1 && this.showImage2 && this.showImage3) {
      this.showChangeImagesButton = true;
    }
  },
  computed: {
    flyersArray() {
      return this.flyers.split(',');
    },
    hasThreeLinks() {
      return (
        this.eventSelected.flyer &&
        this.eventSelected.flyer.split(',').length === 3
      );
    },
    flyerLinks() {
      return this.eventSelected.flyer
        ? this.eventSelected.flyer.split(',')
        : [];
    },
    ...mapState('user', ['user']),
    ...mapState('event', ['eventSelected']),
  },
  methods: {
    ...mapMutations("notification", ["show"]),
    changeImages() {
      this.deleteImage1();
      this.deleteImage2();
      this.deleteImage3();
      this.showChangeImagesButton = false;
    },
    printo() {
      console.log(this.eventSelected);
      console.log(this.flyer);
    },
    deleteImage1() {
      this.showImage1 = false;

      this.image.src = null;
      this.$emit('banderaImageChange', false);
    },

    deleteImage2() {
      this.showImage2 = false;
      this.image2.src = null;
      this.$emit('banderaImageChange', false);
    },

    deleteImage3() {
      this.showImage3 = false;
      this.image3.src = null;
      this.$emit('banderaImageChange', false);
    },

    uploadImage(event, callback) {
      const { files } = event.target;
      let img = new Image();
      if (files && files[0]) {
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src);
        }
        const blob = URL.createObjectURL(files[0]);
        img.src = blob;
        img.type = files[0].type;
        img.onload = function () {
          callback(this.width, this.height, this.src, this.type);
        };
      }
    },

    uploadImage2(event, callback) {
      const { files } = event.target;
      if (files && files[0]) {
        const img = new Image();
        if (this.image2.src) {
          URL.revokeObjectURL(this.image2.src);
        }
        const blob = URL.createObjectURL(files[0]);
        img.src = blob;
        img.type = files[0].type;
        img.onload = function () {
          callback(this.width, this.height, this.src, this.type);
        };
      }
    },

    uploadImage3(event, callback) {
      const { files } = event.target;
      let img = new Image();
      if (files && files[0]) {
        if (this.image3.src) {
          URL.revokeObjectURL(this.image3.src);
        }
        const blob = URL.createObjectURL(files[0]);
        img.src = blob;
        img.type = files[0].type;
        img.onload = function () {
          callback(this.width, this.height, this.src, this.type);
        };
      }
    },

    loadImage(x, y, src, type) {
      this.image = {
        src: src,
        type: type,
        width: x,
        height: y
      };
      console.log(`${x}x${y}`);
      console.log(this.flyer);
      const limits = this.flyer ? this.flyerLimitations : this.bannerLimitations;
      if (this.flyer && ( x > limits.minWidth || x < limits.minWidth - 200)) {
        this.show({
          text: this.flyerError,
          color: 'error',
          time: 3000
        })
        this.deleteImage1()
      } else if ( (x > limits.maxWidth || x < limits.maxWidth - 400) ||  y < limits.maxHeight ){
        this.show({
          text: this.bannerError,
          color: 'error',
          time: 3000
        })
        this.deleteImage1()
      }
    },

    loadImage2(x, y, src, type) {
      this.image2 = {
        src: src,
        type: type,
        width: x,
        height: y
      };
      const limits = this.flyer ? this.flyerLimitations : this.bannerLimitations;
      if (this.flyer && ( x > limits.minWidth || x < limits.minWidth - 200)) {
        this.show({
          text: this.flyerError,
          color: 'error',
          time: 3000
        })
        this.deleteImage2()
      } else if ( (x > limits.maxWidth || x < limits.maxWidth - 400) ||  y < limits.maxHeight ){
        this.show({
          text: this.bannerError,
          color: 'error',
          time: 3000
        })
        this.deleteImage2()
      }
    },

    loadImage3(x, y, src, type) {
      this.image3 = {
        src: src,
        type: type,
        width: x,
        height: y
      };
      const limits = this.flyer ? this.flyerLimitations : this.bannerLimitations;
      if (this.flyer && ( x > limits.minWidth || x < limits.minWidth - 200)) {
        this.show({
          text: this.flyerError,
          color: 'error',
          time: 3000
        })
        this.deleteImage3()
      } else if ( (x > limits.maxWidth || x < limits.maxWidth - 400) ||  y < limits.maxHeight ){
        this.show({
          text: this.bannerError,
          color: 'error',
          time: 3000
        })
        this.deleteImage3()
      }
    },

    async cropImage() {
      const promises = [];

      // Verifica si la primera imagen se debe guardar
      if (this.image.src) {
        const canvas = this.$refs.cropper.getResult().canvas;
        if (canvas) {
          const blob = await new Promise((resolve) => {
            canvas.toBlob(
              (blob) => {
                resolve(blob);
              },
              'image/jpeg',
              1
            );
          });
          const file = new File([blob], 'banner1.jpeg', { type: 'image/jpeg' });
          promises.push(this.saveImage(file));
        }
      }

      // Verifica si la segunda imagen se debe guardar
      if (this.showMultipleImages) {
        if (this.image2.src || this.superArreglo[1] !== null) {
          if (this.superArreglo[1] !== null && this.showImage2) {
            this.image2.src = this.superArreglo[1];
          }
          const canvas2 = this.$refs.cropper2.getResult().canvas;
          if (canvas2) {
            const blob2 = await new Promise((resolve) => {
              canvas2.toBlob(
                (blob) => {
                  resolve(blob);
                },
                'image/jpeg',
                1
              );
            });
            const file2 = new File([blob2], 'banner2.jpeg', {
              type: 'image/jpeg',
            });
            promises.push(this.saveImage(file2));
          }
        }
      }

      // Verifica si la tercera imagen se debe guardar
      if (this.showMultipleImages) {
        if (this.image3.src || this.superArreglo[2] !== null) {
          const canvas3 = this.$refs.cropper3.getResult().canvas;
          if (canvas3) {
            const blob3 = await new Promise((resolve) => {
              canvas3.toBlob(
                (blob) => {
                  resolve(blob);
                },
                'image/jpeg',
                1
              );
            });
            const file3 = new File([blob3], 'banner3.jpeg', {
              type: 'image/jpeg',
            });
            promises.push(this.saveImage(file3));
          }
        }
      }

      await Promise.all(promises);

      this._closeCropper();
    },

    // Función para emitir la imagen para guardarla
    async saveImage(image) {
      return new Promise((resolve) => {
        this.$emit('submitImage', image);
        resolve();
      });
    },

    _closeCropper() {
      this.$emit('close');
    },

    percentsRestriction({
      minWidth,
      minHeight,
      maxWidth,
      maxHeight,
      imageSize,
    }) {
      return {
        maxWidth: maxWidth,
        maxHeight: maxHeight,
        minWidth: minWidth,
        minHeight: minHeight,
      };
    },

    handleSwitchChange() {
      console.log('please', this.flyers);
      this.$emit('banderaImageChange', this.banderaImage);

      this.$emit('updateMultipleImages', this.showMultipleImages);
      if (!this.showMultipleImages) {
        this.image2.src = null;
        this.image3.src = null;
      }
    },
  },
};
</script>
